<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config";
import { mapActions } from 'vuex';


export default {
  page: {
    title: "Bot Registration",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      pagetitle: "Bot Registration",
      initializing: true,
      step: 0
    };
  },
  created() {
    
  },
  computed: {
   
  },
  methods:{
    ...mapActions('bots', {
      createTelegramBot: 'createTelegramBot'
    })
  }
};
</script>

<template>
  <Layout :pagetitle="pagetitle">
    <div v-if="step === 0">
      <div class="d-flex justify-content-center flex-nowrap">
        <div class="card" style="max-width:400px; min-width:300px;">
          <div class="card-body">
             <h6>Select the type of bot.</h6> 
             <div class="list-group mt-4 font-size-16">
               <a href="javascript:void(0)" class="list-group-item">
                 <div>
                   <i class="mdi mdi-telegram me-1"/>
                   Telegram
                   <i class="mdi mdi-chevron-right ms-2 float-end"/>
                 </div>
               </a>
             </div>
          </div>
        </div>
      </div>
      
    </div>
  </Layout>
</template>
